<template>
  <b-card :title="`${$t('General.Update')} ${$t('Cost Financial Year')}`">
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <b-form ref="formName">
          <b-row>
            <b-col md="8">
              <b-form-group
                :label="$t('General.Name')"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.Name')"
                  rules="required"
                >
                  <b-form-input
                    v-model.lazy="name"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('AccountingInfo.Current Year')"
                label-for="currentYear"
              >
                <b-form-checkbox
                  id="patymentTerms"
                  v-model="active"
                  :checked="true"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('General.Start Date')"
                label-for="startDate"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.Start Date')"
                  rules="required"
                >
                  <flat-pickr
                    v-model="start_date"
                    class="form-control"
                    :placeholder="$t('General.Start Date')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('General.End Date')"
                label-for="endDate"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.End Date')"
                  rules="required"
                >
                  <flat-pickr
                    v-model="end_date"
                    class="form-control"
                    :placeholder="$t('General.End Date')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showError"
                class="text-left p-1"
              >
                <ul class="list-style-icons mb-0">
                  <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
                </ul>
              </b-alert>
            </b-col>
            <b-col cols="3" />
            <b-col cols="3" />
            <b-col cols="3" />
            <b-col cols="6 text-center p-1">
              <b-button
                variant="primary"
                type="submit"
                block
                @click.prevent="validationForm"
              >
                {{ $t('General.Update') }}
              </b-button>
            </b-col>
            <b-col cols="3" />
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BOverlay, BAlert, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import financialYear from '@/service/accounting/financial-year'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BAlert,
    BFormCheckbox,
    flatPickr,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      id: router.currentRoute.params.id,
      name: '',
      active: false,
      start_date: null,
      end_date: null,
      required,
      showError: false,
      showOverLay: false,
      apiErrors: [],
      locale: this.$i18n.locale,
    }
  },
  async created() {
    this.showOverLay = true
    await financialYear.oneAxios(this.id).then(response => {
      this.name = response.data.name
      this.active = response.data.active === 1
      this.start_date = response.data.start_date
      this.end_date = response.data.end_date
      this.showError = false
    }).catch(error => {
      this.apiErrors = error
      this.showError = true
      return error
    }).finally(() => { this.showOverLay = false })
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.putApi()
        }
        this.switchLoc()
      })
    },
    async putApi() {
      this.showOverLay = true
      const user = JSON.parse(localStorage.getItem('userData'))
      await financialYear.putAxios(this.id, {
        name: this.name,
        active: this.active ? 1 : 0,
        suspended: 0,
        start_date: this.start_date,
        end_date: this.end_date,
        user_id: user.id,
      }).then(() => {
        this.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.SavedSuccessfully'), 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.SavedError'), 'danger')
      }).finally(() => {
        this.showOverLay = false
      })
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
